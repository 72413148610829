export const formatCurrency = (value, locale, style, currency, minDec, maxDec) => {
    if (!value) return '';
    if (typeof value !== 'string') value = value.toString();
    const number = parseFloat(value.replace(/[^0-9.-]+/g,""));
    if (isNaN(number)) return '';
    return new Intl.NumberFormat(locale, {
      style: style,
      currency: currency,
      minimumFractionDigits: minDec,
      maximumFractionDigits: maxDec,
    }).format(number);
  };