import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function MediaCard({ title, description1, description2, 
  buttonLabel, onClick, enableSecondaryButton, secondaryButtonLabel, secondaryButtonOnClick }) {
  return (
    <Card >
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description1}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description2}
        </Typography>
      </CardContent>
      
      <CardActions>
        <Button size="small"
          onClick={onClick}
        >
          {buttonLabel}
        </Button>
        {enableSecondaryButton ?
        <Button size="small"
          onClick={secondaryButtonOnClick}
        >
          {secondaryButtonLabel}
        </Button> 
        : null
        }
      </CardActions>
    </Card>
  );
}
