import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useOutletContext } from "react-router-dom";

const CONTACT_PHONE = process.env.REACT_APP_CONTACT_PHONE;

export default function HomeInside() {
  const {whatsappUrl} = useOutletContext();

  const handleWhatsAppClick = () => {
    window.open(whatsappUrl, '_blank');
  };

    return (
      <Grid container spacing={3}>

        <Grid item xs={12} md={12} lg={12}>

          <Button variant="contained"
            sx={{ backgroundColor: 'primary.light',
              color: 'primary.contrastText'}}
            size="large"
            fullWidth
            component={Link}
            to="/availability"
            >
              Ver disponibilidad de Clubes
          </Button>
              
        </Grid>

        <Grid item xs={12} md={12} lg={12}>

          <Button variant="contained"
            size="large"
            fullWidth
            endIcon={<WhatsAppIcon />}
            onClick={handleWhatsAppClick}
            sx={{ backgroundColor: 'primary.light',
              color: 'primary.contrastText'}}
            >
              Dejar comentarios y sugerencias
          </Button>
              
        </Grid>
        
      </Grid>
    );
  }