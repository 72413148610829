import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import Link from '@mui/material/Link';
import {Link} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { postAPI, getData } from '../utils/api';
import { useState } from "react";
import SnackbarAlert from '../Components/SnackbarAlert';
import { validateEmail, validatePhone } from '../utils/formValidators';
import { useNavigate } from "react-router-dom";
import PasswordField from '../Components/PasswordField';
import CircularProgress from '@mui/material/CircularProgress';
import { set } from 'date-fns';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        MatchPlay
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const errorMessages = {
  "The password is too similar to the first name.": "La contraseña es muy similar al nombre",
  "This password is too short. It must contain at least 8 characters." : "La contraseña es muy corta. Debe contener al menos 8 caracteres.",
  "This password is too common." : "La contraseña es muy común.",
  "This password is entirely numeric." : "La contraseña es completamente numérica.",
  "User with this phone number already exists.": "El número de teléfono ingresado ya está registrado.",
  "A user with that username already exists.": "El email ingresado ya está registrado.",
  // Add more mappings as needed
};

const translateErrorMessage = (message) => {
  return errorMessages[message] || message;
};

export default function SignUp() {
  const [activateAlert, setActivateAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const [isFilled, setisFilled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '+56',
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
  };

  const handleEmailChange = (event) => {
    let { value } = event.target;
    if (validateEmail(value)) {
      value = value.toLowerCase();
    }
    setFormData((prevData) => ({
      ...prevData,
      email: value,
    }));
  };

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    if (value.startsWith('+56')) {
      setFormData((prevData) => ({
        ...prevData,
        phone: value,
      }));
    }
    if (value.length === 12) {
      validatePhone(value, "CL") ? setPhoneError(null) : 
      setPhoneError('Número de teléfono inválido, ejemplo: +56912345678');
    }
  };

  React.useEffect(() => {
      setisFilled(
          formData.email.length > 0
          && formData.password.length > 0
          && formData.firstName.length > 0
          && formData.lastName.length > 0
          && formData.phone.length > 0
      );
  }, [formData]);
  
  const handleBlurEmail = () => {
    validateEmail(formData.email) ? setEmailError(null) :
      setEmailError('Email inválido');
  };

  const handleBlurPhone = () => {
    validatePhone(formData.phone, "CL") ? setPhoneError(null) : 
      setPhoneError('Número de teléfono inválido, ejemplo: +56912345678');
  };

  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);

  const handleClose = () => {
    setActivateAlert(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
 
    if (data.get('email').length > 0
        && data.get('password').length > 0
        && data.get('firstName').length > 0
        && data.get('lastName').length > 0
        && data.get('phone').length > 0
        && data.get('email').includes('@')
        && data.get('email').includes('.')
      ) {
      const API_URL = process.env.REACT_APP_API_URL;
      const responseData = await postAPI(`${API_URL}/auth/users/`,
        { username: data.get('email'), 
          password: data.get('password'),
          email: data.get('email'),
          first_name: data.get('firstName')+'-'+data.get('lastName'),
          last_name: data.get('phone'),
        });

      if (responseData?.username || responseData?.last_name) {
        let userNameError;
        let lastNameError;
        // if the response has array elements, then show errors
        if (Array.isArray(responseData?.username)) {
          userNameError = responseData?.username?.[0];
        } else {
          userNameError = null;
        }
        if (Array.isArray(responseData?.last_name)) {
          lastNameError = responseData?.last_name?.[0];
        } else {
          lastNameError = null;
        }

        if (userNameError || lastNameError) {
          setAlertMessage(translateErrorMessage(userNameError) + ' ' + translateErrorMessage(lastNameError));
          setSeverity("error");
  
        } else { if (responseData?.username === data.get('email')) {
          setAlertMessage("Revisa tu correo para activar tu cuenta y poder iniciar sesión.");
          setSeverity("success");
          //timeout and redirect
          setTimeout(() => {
            navigate("/signin");
          }, 6000);
    
        } else {
          setAlertMessage("Error al crear usuario. Inténtalo de nuevo.");
          setSeverity("error");
        }
        }
        setActivateAlert(true);
      } 

      if (responseData?.password) {
        setAlertMessage(translateErrorMessage(responseData?.password[0]));
        setSeverity("error");
        setActivateAlert(true);

      }

      setIsLoading(false);

      } 

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Creación Cuenta
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  onChange={handleChange}
                  required
                  fullWidth
                  id="firstName"
                  label="Nombre"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  onChange={handleChange}
                  fullWidth
                  id="lastName"
                  label="Apellido"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="phone"
                  label="Número de teléfono"
                  type="tel"
                  id="phone"
                  autoComplete="tel"
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  onBlur={handleBlurPhone}
                  error={!!phoneError}
                  helperText={phoneError}
                  inputProps={{
                    maxLength: 12,
                    minLength: 12,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleEmailChange}
                  onBlur={handleBlurEmail}
                  error={!!emailError}
                  helperText={emailError}
                />
              </Grid>

              <PasswordField handleChange={handleChange} />
              
            </Grid>
                
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading || !isFilled || !!emailError || !!phoneError}
            >
              {isLoading ? (
                  <>
                    <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                    Creando cuenta
                  </>
                ) : (
                  'Crear cuenta'
                )}
            </Button>
            <SnackbarAlert
            open={activateAlert}
            onClose={handleClose}
            severity={severity}  // Puedes cambiar a "error", "warning", "info"
            message={alertMessage}
            ms={6000}  // Duración en milisegundos (6000ms = 6s)
            vertical='top'
            horizontal='right'
            />
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/signin" variant="body2">
                  Ya tienes cuenta? Inicia Sesión
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}