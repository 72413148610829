// function that validate email format

export const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  
  // function that validate chilean phone number format
  export const validatePhone = (phone, country) => {
      const re = country === 'CL' ? /^\+?56\d{9}$/ : /^\+?57\d{9}$/;
      return re.test(phone);
      }
  
  // list of chilean bank names
  export const chileanBanks = [
    "Banco de Chile",
    "Banco de Crédito e Inversiones",
    "Banco Estado",
    "Banco Santander",
    "Banco Itaú",
    "Scotiabank",
    "Banco Security",
    "Banco Falabella",
    "Banco Ripley",
    "Banco Consorcio",
    "Banco Internacional",
    "Banco BICE",
    "Banco Paris",
    "Banco Penta",
    "Banco BTG Pactual Chile",
    "Banco Corpbanca"];
  
  export const bankAccountTypes = [
      "Cuenta Corriente",
      "Cuenta Vista",
      "Cuenta Rut"
      ];
  
  // function that validate text with only numbers
  export const validateNumber = (text) => {
      const re = /^[0-9]*$/;
      return re.test(text);
  }
  
  // validate password format
  export const validatePassword = (password) => {
      const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
      return re.test(password);
  }