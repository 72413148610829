// Change Password Material UI Form

import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { validatePassword } from '../utils/formValidators';
import { Button, Grid, TextField, Typography, Box, Paper } from '@mui/material';
import { updateData } from '../utils/api';
import SnackbarAlert from './SnackbarAlert';



export default function ChangePassForm() {

    
    const [response, setResponse] = useState("");
    const { logout } = useAuth();
    const [isFilled, setisFilled] = useState(false);
    const [formData, setFormData] = useState({
        old_password: '',
        new_password: '',
        confirm_password: '',
    });
    const [passwordError, setPasswordError] = useState(null);
    const [newPasswordError, setNewPasswordError] = useState(null);
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(null);

    const [activateAlert, setActivateAlert] = useState(false);

    const user = useAuth().userData;
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        }));
    };
    
    React.useEffect(() => {
        setisFilled(
        formData.old_password.length > 0
        && formData.new_password.length > 0
        && formData.confirm_password.length > 0
        );
        if (formData.confirm_password.length > 0) {
            formData.new_password === formData.confirm_password ? setConfirmNewPasswordError(null) :
            setConfirmNewPasswordError('Las contraseñas no coinciden');
        };
    }, [formData]);
    
    const handleBlurNewPassword = () => {
        validatePassword(formData.new_password) ? setNewPasswordError(null) :
        setNewPasswordError('La contraseña debe tener al menos 6 caracteres, una letra mayúscula, una minúscula y un número');
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
    
        if (data.get('old_password').length > 0
        && data.get('new_password').length > 0
        && data.get('confirm_password').length > 0
        && data.get('new_password') === data.get('confirm_password')
        ) {
        try {
            //await logout();
            const API_URL = process.env.REACT_APP_API_URL;
            const updateResponse = await updateData(`${API_URL}/change-password/`, user.auth_token, {...formData});
            if (!updateResponse) {
                setActivateAlert(true);
            } else {
                logout();
            }
            
        } catch (error) {
            console.error('Error:', error);
        }
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setActivateAlert(false);
      };

    
    
    return (
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <React.Fragment>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Cambiar contraseña
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    
                    { (true) ? // if create button is pressed, form is displayed  
                    <>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <TextField
                        required
                        onChange={handleChange}
                        fullWidth
                        name="old_password"
                        label="Contraseña"
                        type="password"
                        id="old_password"
                        autoComplete="old_password"
                        error={!!passwordError}
                        helperText={passwordError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        required
                        onChange={handleChange}
                        fullWidth
                        name="new_password"
                        label="Nueva Contraseña"
                        type="password"
                        id="new_password"
                        autoComplete="new_password"
                        error={!!newPasswordError}
                        helperText={newPasswordError}
                        onBlur={handleBlurNewPassword}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        required
                        onChange={handleChange}
                        fullWidth
                        name="confirm_password"
                        label="Confirmar Nueva Contraseña"
                        type="password"
                        id="confirm_password"
                        autoComplete="confirm_password"
                        error={!!confirmNewPasswordError}
                        helperText={confirmNewPasswordError}
                        />
                    </Grid>
                        </Grid>
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={!isFilled || !!passwordError || !!newPasswordError || !!confirmNewPasswordError}
                        color="primary"
                        size='small'
                        sx={{ mt: 3, mb: 2 }}
                        >
                            Cambiar contraseña y logout
                        </Button>
                        <SnackbarAlert
                            open={activateAlert}
                            onClose={handleClose}
                            severity="error"  // Puedes cambiar a "error", "warning", "info"
                            message="Contraseña antigua incorrecta"
                            ms={6000}  // Duración en milisegundos (6000ms = 6s)
                            vertical='bottom'
                            horizontal='right'
                        />
                    </>
                :""}
                </Box>
            </React.Fragment>
        </Paper>
    </Grid>

        
    );
}
