import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { CheckCircle, Email, LinkedIn, Instagram, WhatsApp } from '@mui/icons-material';
// Import Poppins font
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import { deepmerge } from '@mui/utils';
//import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';


const palette = {
  primary: {
    main: '#035eec',
  },
  secondary: {
    main: '#a9fa60',
  },
  text: {
    primary: '#000639',
  },
  background: {
    default: '#ffffff',
  },
};

const typography = {
  fontFamily: 'Poppins, Arial, sans-serif', // Set Poppins as the default font
};

const theme = createTheme(deepmerge({ palette }, { typography }));


const services = ['Administra Canchas', 'Precios Dinámicos', 'Pagos Seguros', 'Analytics', 'Administra Colaboradores', 'Mobile Responsive'];

export default function LandingPage() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar position="static">
          <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'left' }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit'}}>
            <img src="/Logo2.png" alt="Logo" style={{ height: '75px' }} />
          </Link>
        </Box>
            <Button color="secondary" variant="contained"
              href="mailto:hola@celiotech.com"
            >
              Contáctanos
            </Button>
          </Toolbar>
        </AppBar>

        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography variant="h3" component="h1" gutterBottom>
              Administra tu Club de Raqueta fácilmente
            </Typography>
            <Typography variant="h5" gutterBottom>
            Optimiza las operaciones, incrementa la eficiencia y mejora la experiencia de los miembros
            </Typography>
            <Box
              component="img"
              sx={{
                height: 'auto',
                width: '100%',
                maxWidth: 400, // Reduced to half of the original 800
                borderRadius: 2,
                boxShadow: 3,
                mt: 2,
              }}
              alt="Padel Court"
              src="/imageCourt.png"
            />
          </Box>

          <Box sx={{ mb: 4, backgroundColor: 'background.default' }}> {/* Changed to white background */}
            <Typography variant="h4" component="h2" gutterBottom align="center">
              Nuestros Servicios
            </Typography>
            <Grid container spacing={2}>
              {services.map((service, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                      <CheckCircle sx={{ color: 'primary.main', mr: 2 }} />
                      <Typography variant="body1">{service}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Get In Touch
            </Typography>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item>
                <Button
                  href="mailto:hola@celiotech.com"
                  startIcon={<Email />}
                  sx={{ color: '#fc4f02' }}
                >
                  Email Us
                </Button>
              </Grid>
              <Grid item>
                <Button
                  href="https://wa.me/56942075185?text=Hola!%20me%20gustaría%20saber%20más%20de%20MatchPlay!"
                  startIcon={<WhatsApp />}
                  sx={{ color: '#fc4f02' }}
                >
                  WhatsApp
                </Button>
              </Grid>
              <Grid item>
                <Button
                  href="https://www.linkedin.com/company/matchplay-racquet-sports-app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<LinkedIn />}
                  sx={{ color: '#fc4f02' }}
                >
                  LinkedIn
                </Button>
              </Grid>
              <Grid item>
                <Button
                  href="https://instagram.com/matchplay_cl"
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<Instagram />}
                  sx={{ color: '#fc4f02' }}
                >
                  Instagram
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: theme => theme.palette.primary.main,
            color: 'white',
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="body1" align="center">
              © 2024 MatchPlay. All rights reserved.
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}