import React, { useState, useMemo, useEffect } from 'react';
import { format, addDays } from 'date-fns';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { MultiSelect } from '../Components/MultiSelect';

import { getData } from '../utils/api';
import { useAuth } from '../hooks/useAuth';
import CircularProgress from '@mui/material/CircularProgress';

// Mock data for demonstration
const data = [
  { club_name: "club1", comuna: "Las Condes", disponibles: 3, start_dttm: "2024-11-11T15:00:00Z", url: "https://example.com" },
  { club_name: "club2", comuna: "Vitacura", disponibles: 0, start_dttm: "2024-11-11T16:00:00Z", url: "https://example.com" },
  { club_name: "club2", comuna: "Lo Barnechea", disponibles: 0, start_dttm: "2024-11-12T16:00:00Z", url: "https://example.com" },
  { club_name: "club3", comuna: "Las Condes", disponibles: 2, start_dttm: "2024-11-11T17:00:00Z", url: "https://example.com" },
  { club_name: "club1", comuna: "Las Condes", disponibles: 1, start_dttm: "2024-11-11T18:00:00Z", url: "https://example.com" },
  { club_name: "club3", comuna: "Vitacura", disponibles: 2, start_dttm: "2024-11-12T15:00:00Z", url: "https://example.com" },
];

const regions = ["Las Condes", "Vitacura", "Lo Barnechea"];
function generateTimeSlots(startTime, endTime, interval) {
  const timeSlots = [];
  let currentTime = new Date();
  currentTime.setHours(startTime.split(':')[0]);
  currentTime.setMinutes(startTime.split(':')[1]);
  currentTime.setSeconds(0);
  currentTime.setMilliseconds(0);

  const endTimeDate = new Date();
  endTimeDate.setHours(endTime.split(':')[0]);
  endTimeDate.setMinutes(endTime.split(':')[1]);
  endTimeDate.setSeconds(0);
  endTimeDate.setMilliseconds(0);

  while (currentTime <= endTimeDate) {
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    timeSlots.push(`${hours}:${minutes}`);
    currentTime.setMinutes(currentTime.getMinutes() + interval);
  }

  return timeSlots;
}

const timeSlots = generateTimeSlots('06:00', '23:30', 30);

function getDayLabel(dayIndex) {
  const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
  return daysOfWeek[dayIndex];
}

function generateDaysList() {
  const daysList = [
    { label: "Hoy", value: 0 },
    { label: "Mañana", value: 1 },
  ];
  const startValue = 2;

  for (let i = 0; i < 5; i++) {
    const futureDate = addDays(new Date(), i + 2);
    const dayOfWeek = futureDate.getDay();
    daysList.push({
      label: `${getDayLabel(dayOfWeek)} ${futureDate.getDate()}`,
      value: (startValue + i),
    });
  }

  return daysList;
}

export default function AvailableClubs() {
  const [availabilities, setAvailabilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [when, setWhen] = useState(0);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [shownClubs, setShownClubs] = useState([]);
  const [shownTimes, setShownTimes] = useState([]);


  const user = useAuth().userData;
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchAvailability = async () => {
      let allResults = [];
      let nextUrl = `${API_URL}/availabilities/`;

      try {
        while (nextUrl) {
          const response = await getData(nextUrl, user.auth_token);
          allResults = [...allResults, ...response.results];
          nextUrl = response.next;
        }
        setAvailabilities(allResults);
      } catch (error) {
        console.error('Error fetching availabilities:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAvailability();
  }, []);

  const filteredData = useMemo(() => {
    return availabilities.filter((row) => {
      const rowDate = new Date(row.start_dttm_plus_3h);
      const currentDate = addDays(new Date(), parseInt(when));
      const isSameDate = rowDate.getDate() === currentDate.getDate();
      const isTimeSelected = selectedTimes.length === 0 || selectedTimes.includes(format(rowDate, "HH:mm"));
      // For this example, we'll assume the region is based on the 'comuna'
      const isRegionSelected = selectedRegions.length === 0 || selectedRegions.includes(row.comuna);
      return isSameDate && isTimeSelected && isRegionSelected;
    });
  }, [availabilities, when, selectedRegions, selectedTimes]);


  const uniqueHours = Array.from(new Set(filteredData.map((row) => format(new Date(row.start_dttm_plus_3h), "HH:mm")))).sort();
  const uniqueClubs = Array.from(
    new Map(filteredData.map(item => [item.url, item])).values())
    //new Set(filteredData.map((row) => row.club_name)));

  // Filter out clubs that don't have any available slots
  const clubsWithAvailability = uniqueClubs.filter(club => 
    filteredData.some(row => row.club_name === club && row.disponibles > 0)
  );

  useEffect(() => {
    setShownClubs(uniqueClubs.filter(club =>
      filteredData.some(row => row.club_name === club.club_name && row.disponibles > 0)
    ))
  }, [filteredData]);

  useEffect(() => {
    setShownTimes(uniqueHours.filter(time =>
      filteredData.some(row => format(new Date(row.start_dttm_plus_3h), "HH:mm") === time && row.disponibles > 0)
    ))
  }, [filteredData])

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto' }}>
      <CardHeader title={<Typography variant="h5">Padel Canchas disponibles</Typography>} />
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="when-select-label">Día</InputLabel>
            <Select
              labelId="when-select-label"
              value={when}
              label="Día"
              onChange={(e) => setWhen(e.target.value)}
            >
              {generateDaysList().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <MultiSelect
            options={regions.map((region) => ({ label: region, value: region }))}
            selected={selectedRegions}
            onChange={setSelectedRegions}
            label="Comunas"
          />

          <MultiSelect
            options={timeSlots.map((time) => ({ label: time, value: time }))}
            selected={selectedTimes}
            onChange={setSelectedTimes}
            label="Horas"
          />

          <Typography variant="caption">
            Clickea en los checks para ir a reservar, recuerda volver a filtrar la fecha en la nueva pestaña
          </Typography>

          <Typography variant="caption">
            *puede existir un retraso de 15 min en la información
          </Typography>
          {loading ? <CircularProgress fullWidth color="secondary" sx={{ textAlign: 'center' }} /> :
            <></>}
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="padel club availability table">
            <TableHead>
              <TableRow>
                <TableCell>Hora</TableCell>
                {shownClubs.map((club) => (
                  <TableCell 
                    key={club.club_name} 
                    sx={{ textAlign: 'center', cursor: 'pointer' }}
                    onClick={() => window.open(club.url, "_blank")}  
                  >
                    {club.club_name}  
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {shownTimes.map((hour) => (
                <TableRow key={hour}>
                  <TableCell component="th" scope="row">{hour}</TableCell>
                  {shownClubs.map((club) => {
                    const clubData = filteredData.find(
                      (row) => format(new Date(row.start_dttm_plus_3h), "HH:mm") === hour && row.club_name === club.club_name
                    );
                    return (
                      <TableCell key={`${club.club_name}-${hour}`} sx={{ textAlign: 'center' }}>
                        {clubData ? (
                          clubData.disponibles > 0 ? (
                            <CheckCircleIcon
                              sx={{ color: 'green', cursor: 'pointer' }}
                              onClick={() => window.open(clubData.url, "_blank")}
                            />
                          ) : (
                            <CancelIcon sx={{ color: 'red' }} />
                          )
                        ) : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}