import { format, addHours } from 'date-fns';

//Function to get the current local datetime and add days to it, and return a string
export const addDaysFromDate = (date, days) => {
    let result = date;
    result.setDate(result.getDate() + days);
    return format(result, 'yyyy-MM-dd');
};

// utc datetime to local datetime and return a string
export const utcToLocal = (utcDate, type) => {
    const date = new Date(utcDate);
    if (type === 'time') return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    if (type === 'date2') {
        // format like name of the week day in spanish, day and month
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('es-CL', options);
    }
    if (type === 'date3') {
        // format like day, month, hour and minutes
        const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
        return date.toLocaleDateString('es-CL', options);
    }
    return date.toLocaleString();
};

export const addHoursToTimeString = (timeString, hoursToAdd) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
  
    const newDate = addHours(date, hoursToAdd);
    return format(newDate, 'HH:mm');
  }