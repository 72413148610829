import LogOutButton from "../Components/LogOutButton";
import Grid from '@mui/material/Grid';
import UserInfo from "../Components/UserInfo";
import ChangePassForm from "../Components/ChangePassForm";

export default function Profile() {  
    return (
      <Grid container spacing={3}
        style={{ paddingBottom: '20px' }}>
        <UserInfo />
        <ChangePassForm />
        <LogOutButton />
      </Grid>
      
    );
  }