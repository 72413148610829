import * as React from 'react';
import { useState, useEffect } from 'react'
import { styled, createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FixedBottomNavigation from "../Components/FixedBottomNavigaton";
import { Button } from '@mui/material';
import { Outlet } from 'react-router';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

// TODO remove, this demo shouldn't need to reset the theme.


export default function Home() {
  const [isMobile, setIsMobile] = useState(false)
  const [whatsappUrl, setWhatsappUrl] = useState('')

  const defaultTheme = useTheme();

  useEffect(() => {
    const checkDevice = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    // Check on mount
    checkDevice()

    // Add event listener for window resize
    window.addEventListener('resize', checkDevice)

    // Cleanup
    return () => window.removeEventListener('resize', checkDevice)
  }, [])

  useEffect(() => {
    const phoneNumber = process.env.REACT_APP_CONTACT_PHONE; 
    const message = encodeURIComponent('Hola! Tengo un par de dudas o sugerencias sobre Matchplay') // Replace with your default message

    if (isMobile) {
      setWhatsappUrl(`whatsapp://send?phone=${phoneNumber}&text=${message}`)
    } else {
      setWhatsappUrl(`https://web.whatsapp.com/send?phone=${phoneNumber}&text=${message}`)
    }
  }, [isMobile])

  const handleWhatsAppClick = () => {
    window.open(whatsappUrl, '_blank')
  };
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex', marginBottom: '2px' }}>
        <CssBaseline />
        <AppBar position="absolute">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Espacio flexible a la izquierda para que el Typography se quede centrado */}
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'left' }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit'}}>
            <img src="/Logo2.png" alt="Logo" style={{ height: '75px' }} />
          </Link>
        </Box>
        {/* El botón está alineado a la derecha */}
        <Button variant="contained" 
          color="secondary"
          size="medium"
          component="a"
              href="https://matchplay.cl/clubs"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'black' }}
          >
              ¿Qué es MatchPlay?
        </Button>
      </Toolbar>
        </AppBar>
        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Outlet context={{ whatsappUrl }}/>
            <FixedBottomNavigation />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}