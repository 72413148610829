import { React, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, Paper, Button, Box } from '@mui/material';
import SnackbarAlert from '../Components/SnackbarAlert';
import { updateData } from '../utils/api';
import { useAuth } from "../hooks/useAuth";
import { formatCurrency } from '../utils/currency';
import { Link } from 'react-router-dom';

export default function ConfirmationReserve() {
    const navigate = useNavigate();
    const location = useLocation();
    const { reserveData } = location.state || {};

    const [activateAlert, setActivateAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [severity, setSeverity] = useState("error");

    const user = useAuth().userData;
    const API_URL = process.env.REACT_APP_API_URL;

    const handleGoToReservations = () => {
        navigate('/reservations');
    };

    const handleCancellation = async () => {
        const response = await updateData(`${API_URL}/reservations/${reserveData.reservation}/`, 
          user.auth_token, { is_cancelled: true });
        if (response.id) {
            setSeverity("success");
            setAlertMessage("Reserva cancelada con éxito");
            setActivateAlert(true);
            // redirect to reservations after 3 seconds
            setTimeout(() => {
                navigate('/reservations');
            }, 3000);
        } else {
            setSeverity("error");
            setAlertMessage("Error al cancelar la reserva");
            setActivateAlert(true);
        };
    };

    const handleClose = () => {
        setActivateAlert(false);
    };

  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem', paddingBottom: '4rem' }}>
      <Typography variant="h4" gutterBottom>
        Reserva creada con éxito!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Tu reserva ha sido creada con éxito. Transfiere el monto de la reserva en la próxima hora para confirmarla, con el "Asunto: reserva número {reserveData?.reservation}", de lo contrario se cancelará automáticamente.
      </Typography>
      
      <Paper elevation={3} style={{ padding: '1rem', marginTop: '1rem' }}>
        <Typography variant="h6">Detalles de la Reserva</Typography>
        <Typography variant="body1">Fecha: {reserveData?.date}</Typography>
        <Typography variant="body1">Hora: {reserveData?.time}</Typography>
        <Typography variant="body1">Minutos reservados: {reserveData?.minutes}</Typography>
        <Typography variant="body1">Nombre del Club: {reserveData?.club}</Typography>
        <Typography variant="body1">Cancha: {reserveData?.court}</Typography>
        <Typography variant="body1">Dirección del Club: {reserveData?.address}</Typography>
        <Typography variant="body1">Precio Reserva: {formatCurrency(reserveData?.price, 'es-CL', 'currency','CLP',0,0)}</Typography>
      </Paper>

      <Paper elevation={3} style={{ padding: '1rem', marginTop: '1rem' }}>
        <Typography variant="h6">Datos cuenta Bancaria</Typography>
        <Typography variant="body1">{reserveData.bank.owner_name}</Typography>
        <Typography variant="body1">{reserveData.bank.owner_rut}</Typography>
        <Typography variant="body1">{reserveData.bank.account_type}</Typography>
        <Typography variant="body1">{reserveData.bank.account_number}</Typography>
        <Typography variant="body1">{reserveData.bank.name}</Typography>
        <Typography variant="body1">{reserveData.bank.email}</Typography>

      </Paper>
      
      <Box textAlign="center" marginTop="2rem">
        <Button variant="contained" color="primary" onClick={handleGoToReservations}>
          Ir a Mis Reservas
        </Button>
      </Box>

      <Box textAlign="center" >
      <Link to="/reserve">
          <Button variant="contained" color="primary" sx={{marginTop: '1rem'}}>
            Crear otra Reserva
          </Button>
      </Link>
      </Box>

      <Box textAlign="center" marginTop="2rem">
        <Button variant="text" color="primary" onClick={handleCancellation}>
          Cancelar Reserva
        </Button>
      </Box>
      <SnackbarAlert
            open={activateAlert}
            onClose={handleClose}
            severity={severity}  // Puedes cambiar a "error", "warning", "info"
            message={alertMessage}
            ms={3000}  // Duración en milisegundos (6000ms = 6s)
            vertical='top'
            horizontal='right'
            />   
    </Container>
  );
}