// theme.js
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

const palette = {
  primary: {
    main: '#035eec',
  },
  secondary: {
    main: '#a9fa60',
  },
  text: {
    primary: '#000639',
  },
  background: {
    default: '#ffffff',
  },
};

const typography = {
  fontFamily: 'Poppins, Arial, sans-serif', // Set Poppins as the default font
};

const theme = createTheme(deepmerge({ palette }, { typography }));

export default theme;