import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Link} from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { getData, postAPI } from '../utils/api';
import SnackbarAlert from '../Components/SnackbarAlert';
import CircularProgress from '@mui/material/CircularProgress';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        MatchPlay
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const errorMessages = {
  "This password is too short. It must contain at least 8 characters." : "La contraseña es muy corta. Debe contener al menos 8 caracteres.",
  "This password is too common." : "La contraseña es muy común.",
  "This password is entirely numeric." : "La contraseña es completamente numérica.",
  "Unable to log in with provided credentials." : "Usuario o contraseña incorrectos",
  // Add more mappings as needed
};

const translateErrorMessage = (message) => {
  return errorMessages[message] || 'Error desconocido. Inténtalo de nuevo.';
};

export default function SignIn() {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const [activateAlert, setActivateAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);


  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (event) => {

  // Takes email and password and makes a token authentication

  event.preventDefault();
  setIsLoading(true);
  const data = new FormData(event.currentTarget);

  const responseData = await postAPI(`${API_URL}/auth/token/login/`,
                                    { username: username.toLowerCase(), 
                                      password: password
                                    });
 
  const userResponse = await getData(`${API_URL}/auth/users/me/`, responseData.auth_token);
  const groupsResponse = await getData(`${API_URL}/groups/`, responseData.auth_token);
  // check if array of objects contain one with field name = "buyers_free"
  const isBuyer = groupsResponse?.results.find(group => group.name === "buyers_free");

  if (userResponse?.id && isBuyer) {
    await login(
      { username: username, 
        auth_token: responseData.auth_token,
        user_id: userResponse.id,
      }
    );
  } else { 
      postAPI(`${API_URL}/auth/users/resend_activation/`, { email: username }); // not sended if user is already active
      setAlertMessage(responseData?.non_field_errors ? translateErrorMessage(responseData.non_field_errors[0]) : "No se pudo iniciar sesión");
      setActivateAlert(true);
    }

    setIsLoading(false);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setActivateAlert(false);
  };

  const handleClick = async (event) => {
    event.preventDefault();
    const responseRecovery = await postAPI(`${API_URL}/auth/users/reset_password/`,
                                    { email: username});
    if (responseRecovery.email) {
      if (responseRecovery.email[0] === "This field may not be blank." || 
          responseRecovery.email[0] === "Enter a valid email address.") { 
        setAlertMessage("Ingrese un correo válido");
      } else {
        setAlertMessage(responseRecovery.email[0]);
      }
      setActivateAlert(true);
    } else {
      setAlertMessage("Revise su correo para recuperar su contraseña");
      setActivateAlert(true);
    }
  }


  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar Sesión
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              onChange={(e) => setUsername(e.target.value)}
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? (
                  <>
                    <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                    Iniciar Sesión
                  </>
                ) : (
                  'Iniciar Sesión'
                )}
            </Button>

            <Link to={'/signup'}>
              <Button 
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1, backgroundColor: 'primary.light'}}
                >
                  Crear cuenta
              </Button>
            </Link>
            <SnackbarAlert
            open={activateAlert}
            onClose={handleClose}
            severity="warning"  // Puedes cambiar a "error", "warning", "info"
            message={alertMessage}
            ms={6000}  // Duración en milisegundos (6000ms = 6s)
            vertical='top'
            horizontal='right'
            />
            <Grid container>
              <Grid item xs>
                <Link variant="body2" 
                  onClick={handleClick}
                  disabled={true}
                >
                  Recuperar contraseña
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}