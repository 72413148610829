import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import DenseTable from '../Components/DenseTable';
import MediaCard from '../Components/MediaCard';
import { getData, updateData } from '../utils/api';
import { useAuth } from '../hooks/useAuth';
import { utcToLocal } from '../utils/datetimefunctions';
import CustomizedDataGrid from '../Components/CustomizedDataGrid';
import Chip from '@mui/material/Chip';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarAlert from '../Components/SnackbarAlert';
import Box from '@mui/material/Box';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Portal } from '@mui/base/Portal';
import Typography from '@mui/material/Typography';
import { formatCurrency } from '../utils/currency';
import Button from '@mui/material/Button';

export default function Reservations() {  
  const [reservations, setReservations] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [nextReservation, setNextReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activateAlert, setActivateAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [open, setOpen] = useState(false);
  const [bankInfo, setBankInfo] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClick = (value) => {
    setBankInfo(value)
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  
  const user = useAuth().userData;
  const API_URL = process.env.REACT_APP_API_URL;


  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await getData(`${API_URL}/reservations/`, user.auth_token);
        setReservations(response.results);
        if (response.results[0]) {
        
          setNextReservation(response.results.filter(reserve => !reserve.is_cancelled)[0]);
        }
      } catch (error) {
        console.error('Error fetching reservations:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchReservations();
  }, []);


  const handleCancellation = async (reserveId) => {
    try {
    const response = await updateData(`${API_URL}/reservations/${reserveId}/`, 
      user.auth_token, { is_cancelled: true });
      
    if (response?.id) {
        setSeverity("success");
        setAlertMessage("Reserva cancelada con éxito");
        setActivateAlert(true);
        setReservations(
          reservations.map((reserve) => { // update is_cancelled state
            if (reserve.id === reserveId) {
              return { ...reserve, is_cancelled: true };
            }
            return reserve;
          }).sort((a, b) => { // sort by is_cancelled and start_dttm
              if (a.is_cancelled === b.is_cancelled) {
                return a.start_dttm - b.start_dttm
              }
              return a.is_cancelled ? 1 : -1;
            }
          )
        );

        // only set nextReservation if there is any to select from
        if (reservations.filter(reserve => !reserve.is_cancelled).length >= 2) {
        setNextReservation(reservations.filter(reserve => !reserve.is_cancelled && reserve.id !== reserveId)[0]);
        } else {
          setNextReservation(null);
        }
        if (selectedRow && reserveId === selectedRow?.id) {
          setSelectedRow(null);
        }
    } 
  } catch (error) {
      const errorMessage =  "No se puede cancelar la reserva con menos de 12 horas de anticipación.";
      setSeverity("error");
      setAlertMessage(errorMessage);
      setActivateAlert(true);
    
    };
  };

  const handleClose = () => {
    setActivateAlert(false);
  };

  const handleRowClick = (params) => {
    if (params.row.status == 'Activa' && nextReservation.id !== params.row.id) {
      setSelectedRow(
        reservations.find(reserve => reserve.id === params.row.id)
      );
    }
  }
  

  const bankStyles = {
    position: 'fixed',
    width: 300,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 1,
    bgcolor: 'background.paper',
  };

  const columns = [
    { field: 'start_dttm', headerName: 'Día y Hora', flex: 1.5, minWidth: 120 },
    
    {
      field: 'status',
      headerName: 'Estado',
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => renderStatus(params.value),
    },
  
    {
      field: 'payStatus',
      headerName: 'Pago',
      flex: 0.5,
      minWidth: 110,
      renderCell: (params) => renderPayStatus(params.value),
    },
    
    {
      field: 'club_name',
      headerName: 'Club',
      //headerAlign: 'right',
      //align: 'right',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      minWidth: 110,
      flex: 1,
      renderCell: (params) => {
        if (params.row.status === 'Activa') {
          return (
            <Button 
              size='small' 
              variant='contained' 
              onClick={() => handleCancellation(params.row.id)}
              sx={{ padding: '2px 6px', fontSize: '0.75rem' }}
            >
                Cancelar
            </Button>
          );
        }
        return null;
      },
    },
    
  ];
  
    function renderStatus(status) {
      const colors = {
        Online: 'success',
        Offline: 'default',
        Cancelada: 'error',
        Activa: 'success',
      };
    
      return <Chip label={status} color={colors[status]} size="small" />;
    }
  
    function renderPayStatus(status) {
      const colors = {
        Devuelto: 'default',
        "No aplicable": 'default',
        "En devolución": 'warning',
        "Por pagar": 'error',
        Pagado: 'success',
      };
    
      return <Chip label={status} color={colors[status]} size="small" />;
    }

  return (
    <Grid container spacing={3}>
      {  loading ?
        (
          <Grid item xs={12} md={12} lg={12}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
          >
            <CircularProgress />
          </Grid>
        ) : 

        (
        nextReservation ? (
      <Grid item xs={12} md={12} lg={12}>
        <MediaCard title="Próxima reserva"
          description1={`El ${utcToLocal(nextReservation.start_dttm, "date2")} a las 
          ${utcToLocal(nextReservation.start_dttm, "time")}. 
          ${nextReservation.club_name}, ${nextReservation.court_name}.`}
          description2={`Dirección: ${nextReservation.club_address}. Precio: ${formatCurrency(nextReservation.price, 'es-CL', 'currency','CLP',0,0)}.`}
          buttonLabel="Cancelar"
          onClick={() => handleCancellation(nextReservation.id)}
          enableSecondaryButton={!nextReservation.is_paid}
          secondaryButtonLabel="Ver datos de pago"
          secondaryButtonOnClick={() => handleClick(nextReservation.club_bank)}
          />
          {open ? (
            <ClickAwayListener onClickAway={handleClickAway}>
              <div>
                <Portal>
                  <Box sx={bankStyles}>
                  <Typography variant="h6">Datos cuenta Bancaria</Typography>
                  <Typography variant="body1">{bankInfo.owner_name}</Typography>
                  <Typography variant="body1">{bankInfo.owner_rut}</Typography>
                  <Typography variant="body1">{bankInfo.account_type}</Typography>
                  <Typography variant="body1">{bankInfo.account_number}</Typography>
                  <Typography variant="body1">{bankInfo.name}</Typography>
                  <Typography variant="body1">{bankInfo.email}</Typography>
                  </Box>
                </Portal>
              </div>
          </ClickAwayListener>
        ) : null}

      </Grid>
      

      )

      : null
      )

      }
      {
        selectedRow ? 
        (
          <Grid item xs={12} md={12} lg={12}>
            <MediaCard title="Reserva Seleccionada"
            description1={`El ${utcToLocal(selectedRow.start_dttm, "date2")} a las 
            ${utcToLocal(selectedRow.start_dttm, "time")}. 
            ${selectedRow.club_name}, ${selectedRow.court_name}.`}
            description2={`Dirección: ${selectedRow.club_address}. Precio: ${formatCurrency(selectedRow.price, 'es-CL', 'currency','CLP',0,0)}.`}
            buttonLabel="Cancelar"
            onClick={() => handleCancellation(selectedRow.id)}
            enableSecondaryButton={!selectedRow.is_paid}
            secondaryButtonLabel="Ver datos de pago"
            secondaryButtonOnClick={() => handleClick(selectedRow.club_bank)}
            />
          </Grid>
        )
        : null
      }
      
      <Grid item xs={12} md={12} lg={12}>
        <CustomizedDataGrid 
          columns={columns} 
          rows={formatData(reservations)}
          onRowClick={handleRowClick}
        />
      </Grid>
      <SnackbarAlert
            open={activateAlert}
            onClose={handleClose}
            severity={severity}  // Puedes cambiar a "error", "warning", "info"
            message={alertMessage}
            ms={6000}  // Duración en milisegundos (6000ms = 6s)
            vertical='top'
            horizontal='right'
            />   

    </Grid>
  );
}

const formatData = (data) => {
  return data.map((item) => {
    return {
      id: item.id,
      start_dttm: utcToLocal(item.start_dttm, "date3"),
      status: item.is_cancelled ? 'Cancelada' : 'Activa',
      payStatus: item.is_refunded ? 'Devuelto' : 
                item.is_cancelled && item.is_paid && !item.is_refunded ? 'En devolución' :
                item.is_cancelled && !item.is_paid ? 'No aplicable' :
                !item.is_cancelled && !item.is_paid ? 'Por pagar' : 
                !item.is_cancelled && item.is_paid ? 'Pagado' : 
                'Error',
      club_name: item.club_name,
    };
  });
};



  function renderSparklineCell(params) {
    const data = getDaysInMonth(4, 2024);
    const { value, colDef } = params;
  
    if (!value || value.length === 0) {
      return null;
    }
  
    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <SparkLineChart
          data={value}
          width={colDef.computedWidth || 100}
          height={32}
          plotType="bar"
          showHighlight
          showTooltip
          colors={['hsl(210, 98%, 42%)']}
          xAxis={{
            scaleType: 'band',
            data,
          }}
        />
      </div>
    );
  }

  function getDaysInMonth(month, year) {
    const date = new Date(year, month, 0);
    const monthName = date.toLocaleDateString('en-US', {
      month: 'short',
    });
    const daysInMonth = date.getDate();
    const days = [];
    let i = 1;
    while (days.length < daysInMonth) {
      days.push(`${monthName} ${i}`);
      i += 1;
    }
    return days;
  }