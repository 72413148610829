import React, {useEffect, useState} from 'react';

import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {Link, useLoaderData, useNavigate } from "react-router-dom";

import {  postAPI } from '../utils/api';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function PassRecovery() {
  
  const {params} = useLoaderData();
  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    const activateAccount = async () => {
      try {  
    const API_URL = process.env.REACT_APP_API_URL;
    const responseData = await postAPI(`${API_URL}/auth/users/activation/`,
                                      { uid: params.uid, 
                                        token: params.token,
                                      });

    if (responseData.detaiil) {
      setResponse(responseData.detail);
    } else { if (responseData.uid) {
        setResponse(responseData.uid);
      } else { if (responseData.token) {
          setResponse(responseData.token);
        } else {
          setResponse("Cuenta activada con éxito, te estamos redirigiendo a la página de inicio");
        }}}}
        
    catch (error) {
      setResponse(error);
    }};

    activateAccount();
  },[]);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/")
    }, 5000);
    return () => clearTimeout(timer);
  }, [response]);
       
  return (
    <div id="error-page">
      <h1>Activación de cuenta</h1>
      <p>Este es tu status de activación de cuenta:</p>
      <p>
        <i>{response}</i>
      </p>
    </div>
  );
}