import * as React from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from "../hooks/useAuth";


export default function LogOutButton() {
    
    const { logout } = useAuth();

    const handleLogout = () => {
    logout();
    }

    return (
        <Grid item xs={12}>
            <React.Fragment>
                <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Cerrar sesión" />
                </ListItemButton>
            </React.Fragment>
        </Grid>
    )
}