import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import Link from '@mui/material/Link';

import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Link, useLoaderData} from "react-router-dom";
import { useState } from "react";

import { getData, postAPI } from '../utils/api';
import SnackbarAlert from '../Components/SnackbarAlert';
import { validatePassword } from '../utils/formValidators';
import {Grid} from '@mui/material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function PassRecovery() {
  
  const {params} = useLoaderData();

  const [activateAlert, setActivateAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState('');

  
  const handleChange = (event) => {
    setNewpassword(event.target.value);
    validatePassword(event.target.value) ? setNewPasswordError(null) :
      setNewPasswordError('La contraseña debe tener al menos 6 caracteres, una letra mayúscula, una minúscula y un número');
  }
  
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (event) => {
  event.preventDefault();

  const responseData = await postAPI(`${API_URL}/auth/users/reset_password_confirm/`,
                                    { uid: params.uid, 
                                      token: params.token,
                                      new_password: newpassword
                                    });

  if (responseData.uid || responseData.token || responseData.new_password) {
    setAlertMessage("Link de reactivación inválido")
    setAlertSeverity("error")
    setActivateAlert(true);
  } else {
    setAlertMessage("Contraseña cambiada con éxito, apriete Ir a Sign in")
    setAlertSeverity("success")
    setActivateAlert(true);
    setNewpassword("");
  }

  };



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setActivateAlert(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recuperar contraseña
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          
            <TextField
              margin="normal"
              onChange={handleChange}
              required
              fullWidth
              value={newpassword}
              name="new_password"
              label="Nueva Contraseña"
              type="password"
              id="new_password"
              autoComplete="new_password"
              error={!!newPasswordError}
              helperText={newPasswordError}
            />
            
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!!newPasswordError || newpassword.length === 0}
                sx={{ mt: 3, mb: 2 }}
              >
                Cambiar contraseña
              </Button>
            
            <SnackbarAlert
            open={activateAlert}
            onClose={handleClose}
            severity={alertSeverity}  // Puedes cambiar a "error", "warning", "info"
            message={alertMessage}
            ms={6000}  // Duración en milisegundos (6000ms = 6s)
            vertical='top'
            horizontal='right'
            />
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/signin" variant="body2">
                  Ir a Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}